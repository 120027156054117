import './App.css';
import Footer from './components/footer/Footer';
import HelpCenter from './components/HelpCenter';
import Navbar from './components/navbar/Navbar';
import Platform from './components/Platform';
import Slider from './components/Slider';
import Started from './components/Started';
import Trusted from './components/Trusted';
import Ultimate from './components/Ultimate';
import Unlock from './components/Unlock';

function App() {
  return (
    <>
      <Navbar />
      <Trusted />
      <Platform />
      <Unlock />
      <Started />
      <Slider />
      <Ultimate />
      <HelpCenter/>
      <Footer />
    </>
  );
}

export default App;
