import React from "react";
import frameone from "@/../../src/images/Frame-one.png";
import frametwo from "@/../../src/images/Frame-two.png";
import framethree from "@/../../src/images/Frame-three.png";

const Unlock = () => {
  const Unlock_data = [
    {
      img: frameone,
      title: "TradingView charts",
      text: "Backtesting with familiar tools never felt so real.",
    },
    {
      img: frametwo,
      title: "Never miss a moment with our replay feature!",
      text: "Our replay feature helps you ensure that all opportunities are studied and accounted for.",
    },
  ];

  return (
    <>
      <div className="text-white">
        <h2 className="flex justify-center text-[56px] font-semibold leadin g-[60px] mx-2">
          Test Your
        </h2>
        <h2 className="flex justify-center text-[56px] font-semibold leading-[60px] mx-2">
          Skills Against Other
        </h2>
        <h2 className="flex justify-center text-[56px] font-semibold leading-[60px] mb-6 mx-2">
          Around The World
        </h2>
        <p className="flex justify-center text-[18px] text-[#E5E6ED] mx-2">
          Compete to win prizes such as prop firm accounts,
        </p>
        <p className="flex justify-center text-[18px] text-[#E5E6ED] mx-2">
          cash prizes, merch, and more!
        </p>
      </div>
      <div className="flex justify-center flex-wrap mt-8 unlockcard">
        {Unlock_data.map((data, index) => {
          return (
            <div
              key={index}
              className="text-white my-3 py-4 px-3 bg-[#2F324180] w-[380px] rounded-xl"
            >
              <div className="flex justify-center">
                <img src={data.img} alt={data.title} className="h-52" />
              </div>
              <h5 className="text-[40px] font-semibold leading-[40px] my-3">
                {data.title}
              </h5>
              <p className="text-[#E5E6ED] text-[18px]">{data.text}</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mt-4">
        <img src={framethree} alt="frame-three" className="w-[50%]" />
      </div>
    </>
  );
};

export default Unlock;
