import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";

const HelpCenter = () => {
  const [name, setName] = useState("");
  const [problem, setProblem] = useState("");

  return (
    <div id="helpcenter" className="text-white">
      <div className="my-12">
        <h2 className="text-[56px] font-semibold flex justify-center leading-[50px] mx-2">
          Have a Problem? Let us know!
        </h2>
      </div>
      <div className="pb-10">
        <div className="max-w-[600px] mx-auto p-4 bg-[#2F324190] rounded-xl">
          <form>
            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ddd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ddd",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#fff",
                },
              }}
            />
            <TextField
              fullWidth
              label="Describe Your Problem"
              variant="outlined"
              multiline
              rows={4}
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              margin="normal"
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ddd",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#ddd",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#fff",
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#5235E8",
                "&:hover": {
                  backgroundColor: "#3E28B5",
                },
              }}
              className="mt-4"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
