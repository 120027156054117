import React from "react";
import iconone from "@/../../src/images/Light blue 1.png";
import icontwo from "@/../../src/images/Light blue 2.png";
import iconthree from "@/../../src/images/Light blue 3.png";
import iconfour from "@/../../src/images/Prize.png";

const Platform = () => {
  const Platform_Data = [
    {
      image: iconone,
      title: "Step 1",
      text: "Create and Register an Account",
    },
    {
      image: icontwo,
      title: "Step 2",
      text: "Joining an available tournament and paying the entry fee.",
    },
    {
      image: iconthree,
      title: "Step 3",
      text: "You will be given a random set of past existing data. Do your best to trade it before your trading period ends!",
    },
    {
      image: iconfour,
      title: "Step 4",
      text: "Prizes and winnings and how they are awarded.",
    },
  ];

  return (
    <div id="features" className="text-white py-14">
      <div className="flex flex-col justify-center items-center w-full">
        <h2 className="text-[56px] font-semibold leading-[60px] flex justify-center mx-2">
          How It Works
        </h2>
      </div>
      <div className="flex mt-10 ">
        <div className="flex  justify-center w-full flex-wrap card ">
          {Platform_Data.map((data, index) => {
            return (
              <div
                className={`pt-4 pb-8 px-6 mx-3 w-[300px] bg-[#2F324180] rounded-xl h-[300px] ${
                  index === 3 ? "mt-6" : ""
                }`}
              >
                <div className="flex justify-center">
                  <img src={data.image} alt={data.title} />
                </div>
                <h5 className="flex justify-center text-[24px] font-semibold my-4">
                  {data.title}
                </h5>
                <p className="flex justify-center text-[#A5ADCF] text-[16px]">
                  {data.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Platform;
