import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#2F324190",
  color: "#fff",
  boxShadow: "none",
  borderRadius: "16px",
  transition: "transform 0.3s ease",
  "&:before": {
    display: "none",
  },
  border: "1px solid #333",
  "&:hover": {
    backgroundColor: "#2F324200",
    transform: "scale(1.02)",
  },
  marginBottom: "16px",
  width: "100%",
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    margin: "0",
  },
  minHeight: "80px",
}));

const Started = () => {

  const faqs = [
    {
      question: "Can I ask a question?",
      answer: "Yes, you can",
    },
    {
      question: "Can I ask a question?",
      answer: "Yes, you can",
    },
    {
      question: "Can I ask a question?",
      answer: "Yes, you can",
    },
    {
      question: "Can I ask a question?",
      answer: "Yes, you can",
    },
    {
      question: "Can I ask a question?",
      answer: "Yes, you can",
    },
  ];

  return (
    <div id="q&a" className="text-white pt-14">
      <div>
        <h1 className="text-[56px] font-semibold flex justify-center leading-[50px] mx-2">
          Q&A
        </h1>
        {/* <p className="text-[18px] text-[#E5E6ED] flex justify-center mt-4 mx-2">
          Join the best backtesting software now to see how intuitive
        </p> */}
        {/* <p className="text-[18px] text-[#E5E6ED] flex justify-center mx-2">
          backtesting can be.
        </p> */}
      </div>

      {/* Accordion Section */}
      <div className="max-w-[1000px] mx-auto my-8 p-4">
        {faqs.map((faq, index) => (
          <CustomAccordion key={index}>
            <CustomAccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography>{faq.question}</Typography>
            </CustomAccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </div>

      {/* <div>
        <p className="text-[18px] text-[#E5E6ED] flex justify-center">
          Accepted Payment Methods
        </p>
        <div className="flex justify-center">
          <img src={PaymentPic} alt="PaymentPic" className="w-1/4" />
        </div>
      </div> */}
    </div>
  );
};

export default Started;
