import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconone from "@/../../src/images/slidericon-one.svg";
import icontwo from "@/../../src/images/slidericon-two.svg";
import iconthree from "@/../../src/images/slidericon-three.svg";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

const SliderOne = () => {
  const [SlideNumber, setCurrentSlideNumber] = useState(0);
  const [isLastIndex, setIsLastIndex] = useState(false);
  const isItMobile = useCheckMobileScreen();

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500, 
    easing: "ease-in-out",
    slidesToShow: isItMobile ? 1 : 3,
    slidesToScroll: 1,
  };
  const Slider_Data = [
    {
      Heading: "Brilliant",
      Text: "Lost for words. Best backtesting program in the world to this date. Everything is perfectly layed out for you, the order placement, skip to next (session, start of day or your own designed time) buttom is brilliant. Save me so much time in front of the computer and as a day trader I appreciate this. 35 dollars a month is nothing compared to the value of this thing. Helping community and good owners is a bonus.",
      IconPic: iconone,
      PicTitle: "Gustav Jansson",
      PicText: "Current FXR User",
    },
    {
      Heading: "Excellent ",
      Text: "Lost for words. Best backtesting program in the world to this date. Everything is perfectly layed out for you, the order placement, skip to next (session, start of day or your own designed time) buttom is brilliant. Save me so much time in front of the computer and as a day trader I appreciate this. 35 dollars a month is nothing compared to the value of this thing. Helping community and good owners is a bonus.",
      IconPic: icontwo,
      PicTitle: "Jack Ming",
      PicText: "Current FXR User",
    },
    {
      Heading: "Gorgeous",
      Text: "Lost for words. Best backtesting program in the world to this date. Everything is perfectly layed out for you, the order placement, skip to next (session, start of day or your own designed time) buttom is brilliant. Save me so much time in front of the computer and as a day trader I appreciate this. 35 dollars a month is nothing compared to the value of this thing. Helping community and good owners is a bonus.",
      IconPic: iconthree,
      PicTitle: "Augustine",
      PicText: "Current FXR User",
    },
    
  ];

  useEffect(() => {
    console.log("The slide is", SlideNumber);
    if (SlideNumber == Slider_Data.length - 1) {
      setIsLastIndex(true);
    } else {
      setIsLastIndex(false);
    }
  }, [SlideNumber]);
  useEffect(() => {
    console.log("the last index is", isLastIndex);
  }, [isLastIndex]);

  return (
    <div className="text-white mb-10">
      <div>
        <h1 className="flex justify-center text-[56px] leading-[50px] mx-2">Here's What Traders </h1>
        <h1 className="flex justify-center text-[56px]">Have To Say</h1>
      </div>
      <div className="flex justify-center py-8  h-[460px] ">
        <Slider
          {...settings}
          beforeChange={(currentSlide, nextSlide) =>
            setCurrentSlideNumber(nextSlide)
          }
          className="w-[70%] "
        >
         {!isItMobile ? ( Slider_Data.map((data, index) => {
            return (
              <div className="">
                {!isLastIndex ? (
                  index == SlideNumber + 1 ? (
                    <div key={index} className={`bg-[#2D2C3D80] py-4 px-3 mx-2 rounded-xl `} >
                      <h4 className="text-[18px] font-semibold">{data.Heading} </h4>
                      <p className="text-[16px] text-[#ACABB9] my-5">{data.Text}</p>
                      <div className="flex">
                        <div>
                          <img src={data.IconPic} alt={data.PicTitle} className="rounded-full mr-3" />
                        </div>
                        <div>
                          <h6 className="text-[18px] font-bold">{data.PicTitle}</h6>
                          <p className="text-[#F5F5F5A1] text-[16px font-semibold]">{data.PicText} </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" h-[390px] flex justify-center items-center flex-col">
                      {" "}
                      <img src={data.IconPic} alt={data.PicTitle} className="rounded-full mr-3" />
                      <h6 className="text-[18px] font-bold mt-2">{data.PicTitle} </h6>
                          <p className="text-[#F5F5F5A1] text-[16px font-semibold]">{data.PicText} </p>

                    </div>
                  )
                ) : index == 0 ? (
                  <div key={index} className={`bg-[#2D2C3D80] py-4 px-3 mx-2 rounded-xl `}>
                    <h4 className="text-[18px] font-semibold"> {data.Heading} </h4>
                    <p className="text-[16px] text-[#ACABB9] my-5">{data.Text} </p>
                    <div className="flex">
                      <div>
                        <img src={data.IconPic} alt={data.PicTitle} className="rounded-full mr-3" />
                      </div>
                      <div>
                        <h6 className="text-[18px] font-bold">{data.PicTitle} </h6>
                        <p className="text-[#F5F5F5A1] text-[16px font-semibold]">{data.PicText}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" h-[390px] flex justify-center items-center flex-col">
                    {" "}
                    <img src={data.IconPic} alt={data.PicTitle}  className="rounded-full mr-3" />
                     <h6 className="text-[18px] font-bold mt-2">{data.PicTitle} </h6>
                          <p className="text-[#F5F5F5A1] text-[16px font-semibold]">{data.PicText} </p>
                  </div>
                )}
              </div>
            );
          }) 
        ) : (
                     Slider_Data.map((data, index) => {
            return (
              <div className="">
             
                 
                    <div key={index} className={`bg-[#2D2C3D80] py-4 px-3 mx-2 rounded-xl `} >
                      <h4 className="text-[18px] font-semibold">{data.Heading} </h4>
                      <p className="text-[16px] text-[#ACABB9] my-5">{data.Text}</p>
                      <div className="flex">
                        <div>
                          <img src={data.IconPic} alt={data.PicTitle} className="rounded-full mr-3" />
                        </div>
                        <div>
                          <h6 className="text-[18px] font-bold">{data.PicTitle}</h6>
                          <p className="text-[#F5F5F5A1] text-[16px font-semibold]">{data.PicText} </p>
                        </div>
                      </div>
                    </div>
                  
          
              </div>
            );
          }) 
        )}
        </Slider>
      </div>
    </div>
  );
};

export default SliderOne;
